/*
 *** 保單查詢
 */

const insuranceFilterTitles = {
    titleType: '制度',
    manCodes: '帳號',
    customerName: '客戶姓名',
    productFilter: '商品名稱或代號',
    receiveDateStart: '受理日(起)',
    receiveDateEnd: '受理日(迄)',
    _seq: [
        'titleType',
        'manCodes',
        'customerName',
        'productFilter',
        'receiveDateStart',
        'receiveDateEnd'
    ]
}

const insuranceColumns = {
    propertyDueStatus: {
        label: '產險到期狀態標誌',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 0,
        widthPx: 0,
        alias: {
            propertyDueStatusTrans: {
                label: '產險到期狀態',
                position: 'center'
            }
        },
        isUnmovable: true
    },
    insDetailLink: {
        label: '明細',
        sortEnabled: false,
        isFilterable: false,
        isUnmovable: true,
        isUnhide: true,
        isExcelHide: true,
        type: 'string',
        position: 'center',
        widthPx: 65,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '查看保單明細及編輯部分欄位',
                mobile: null
            }
        }
    },
    productFilter: {
        label: '商品名稱與代號',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 17,
        widthPx: 170,
        isUnmovable: true
    },
    supplierInfo: {
        label: '最新資訊',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        isExcelHide: true,
        width: 8,
        widthPx: 90,
        isUnmovable: true
    },
    notice: {
        label: '保單通知',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        isExcelHide: true,
        width: 8,
        widthPx: 90,
        isUnmovable: true
    },
    insStatus: {
        label: '保單狀態',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 80
    },
    effectiveDate: {
        label: '生效日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 14,
        widthPx: 105
    },
    receiveDate: {
        label: '受理日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 14,
        widthPx: 105
    },
    propertyDueDate: {
        label: '產險到期日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 14,
        widthPx: 130,
        alias: {
            propertyDueDateTrans: {
                label: '產險到期日',
                position: 'center',
                type: 'date'
            }
        },
        toolTips: {
            hasToolTips: true,
            content: {
                desktop:
                    '系統將自動為您預設到期日為生效日往後推一年，您亦可至保單明細修改',
                mobile:
                    '「產險到期日」系統將自動為您預設到期日為生效日往後推一年，您亦可至「明細」中修改'
            }
        }
    },
    payer: {
        label: '要保人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 11,
        widthPx: 105
    },
    insured: {
        label: '被保人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 11,
        widthPx: 105
    },
    solicitorOri: {
        label: '招攬人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 11,
        widthPx: 105
    },
    supplier: {
        label: '保險公司',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 12,
        widthPx: 110
    },
    insNo: {
        label: '保單號碼',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 17,
        widthPx: 150
    },
    jobTypeText: {
        label: '險種',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 9,
        widthPx: 100
    },
    vehicleNumber: {
        label: '車牌號碼',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 115,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '可至保單明細修改',
                mobile: '「車牌號碼」欄位內容可至「明細」中修改'
            }
        }
    },
    rfyp: {
        label: '總保費',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        unit: '元',
        width: 11,
        widthPx: 120
    }
}

const insuranceContentColumns = {
    supplier: '保險公司',
    insNo: '保單號碼',
    jobTypeText: '險種',
    payType: '繳別',
    payer: '要保人',
    insured: '被保人',
    receiveDate: '受理日',
    effectiveDate: '生效日',
    policyIssueDate: '出單日',
    propertyDueDate: '產險到期日',
    rDate: '客戶簽收日',
    bDate: '回條繳回日',
    receiptDueDate: '回條最晚簽回日',
    applicationDate: '要保書申請日',
    payWayFirstText: '首期繳費方式',
    payWayRenewalText: '續期繳費方式',
    currencyText: '幣別',
    licenseText: '證照別',
    customerSourceText: '客戶來源',
    insuredAge: '客戶投保年齡',
    isLoanPremium: '保費來源為借款或解約',
    introId: '介紹人ID',
    introName: '介紹人姓名',
    solicitorOri: '招攬人',
    vehicleNumber: '車牌號碼',
    amountType: '收款類型',
    payWayText: '項目',
    amount: '金額',
    payDate: '收款日',
    payAccount: '信用卡卡號/銀行帳號',
    payBank: '付款銀行',
    creditCardValidThru: '信用卡有效期限',
    checkNo: '支票號碼',
    checkDate: '支票到期日',
    checkIssuer: '發票人',
    payMan: '付款人',
    modifiedDate: '修改日',
    modifiedName: '修改人',
    remark: '備註'
}

const insuranceProductColumns = {
    productName: {
        label: '商品名稱',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'left',
        total: 'key',
        width: 32,
        widthPx: 230
    },
    productType: {
        label: '',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        width: 3,
        widthPx: 40
    },
    isVoid: {
        label: '停效商品',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        width: 4,
        widthPx: 80
    },
    insItem: {
        label: '險型類別',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        width: 17,
        widthPx: 136
    },
    insAmount: {
        label: '保額',
        sortEnabled: false,
        isFilterable: false,
        // type: 'number',
        position: 'right',
        // total: 'sum',
        // unit: '元',
        width: 13,
        widthPx: 120
    },
    insPeriod: {
        label: '年期',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        unit: '年',
        width: 8,
        widthPx: 64
    },
    rfyp: {
        label: '保費',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 12,
        widthPx: 105
    },
    bRate: {
        label: '佣率',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        // total: 'mean',
        // meanBy: ['FYB', 'RFYP'],
        unit: '%',
        width: 9,
        widthPx: 80
    },
    fyb: {
        label: '代理費',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 13,
        widthPx: 100
    }
}

//服務權紀錄
const insuranceSalesColumns = {
    effectiveDate: {
        label: '生效日',
        sortEnabled: false,
        isFilterable: false,
        type: 'date',
        position: 'left',
        width: 8,
        widthPx: 100
    },
    name: {
        label: '業務員姓名',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 95
    },
    rate: {
        label: '比率',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        unit: '%',
        width: 5,
        widthPx: 85
    },
    title: {
        label: '職級',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        width: 15,
        widthPx: 80
    },
    branchName: {
        label: '營業單位',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    orgBmGroupName: {
        label: '區系統/事業處(部)',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 140
    },
    mentorName: {
        label: '輔導人',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 1,
        width: 10,
        widthPx: 95
    },
    recommenderName: {
        label: '推薦人',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 2
    },
    isVoid: {
        label: '註銷',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'center',
        width: 5,
        widthPx: 50
    },
    modifiedName: {
        label: '修改人',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 1,
        width: 10,
        widthPx: 100
    },
    modifiedDate: {
        label: '修改日',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 2
    },
    remark: {
        label: '備註',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'left',
        width: 10,
        widthPx: 85
    }
}

const insuranceCommissionBonusColumns = {
    bonusName: {
        label: '佣金類別',
        sortEnabled: false,
        isFilterable: false,
        type: 'date',
        position: 'left',
        rowNumber: 1,
        width: 12,
        widthPx: 147
    },
    period: {
        label: '工作月',
        sortEnabled: false,
        isFilterable: false,
        type: 'date',
        position: 'left',
        rowNumber: 2
    },
    handler: {
        label: '招攬人',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 1,
        width: 10,
        widthPx: 100
    },
    takeName: {
        label: '領佣人',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 2
    },
    productCode: {
        label: '商品代號',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 80
    },
    paymentPeriod: {
        label: '年期',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 1,
        width: 10,
        widthPx: 55
    },
    payType: {
        label: '繳別',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 2
    },
    paymentYear: {
        label: '年度',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 1,
        width: 10,
        widthPx: 55
    },
    paymentCount: {
        label: '繳次',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 2
    },
    fyp: {
        label: '保費',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        unit: '元',
        rowNumber: 1,
        width: 12,
        widthPx: 110
    },
    fyb: {
        label: '代理費',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        unit: '元',
        rowNumber: 2
    },
    fya: {
        label: '業績',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        unit: '元',
        width: 11,
        widthPx: 100
    },
    kRate: {
        label: 'K值',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        width: 10,
        widthPx: 80
    },
    fyc: {
        label: '佣金',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        unit: '元',
        width: 12,
        widthPx: 110
    },
    remark: {
        label: '備註',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'left',
        width: 10,
        widthPx: 85
    }
}

const insuranceServiceAllowanceColumns = {
    bonusName: {
        label: '津貼類別',
        sortEnabled: false,
        isFilterable: false,
        type: 'date',
        position: 'left',
        rowNumber: 1,
        width: 12,
        widthPx: 147
    },
    period: {
        label: '工作月',
        sortEnabled: false,
        isFilterable: false,
        type: 'date',
        position: 'left',
        rowNumber: 2
    },
    handler: {
        label: '招攬人',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 1,
        width: 10,
        widthPx: 100
    },
    takeName: {
        label: '領佣人',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 2
    },
    productCode: {
        label: '商品代號',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 80
    },
    paymentPeriod: {
        label: '年期',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 1,
        width: 10,
        widthPx: 55
    },
    payType: {
        label: '繳別',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 2
    },
    paymentYear: {
        label: '年度',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 1,
        width: 10,
        widthPx: 55
    },
    paymentCount: {
        label: '繳次',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 2
    },
    fyp: {
        label: '保費',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        unit: '元',
        rowNumber: 1,
        width: 12,
        widthPx: 110
    },
    fyb: {
        label: '服務津貼',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        unit: '元',
        rowNumber: 2
    },
    fya: {
        label: '業績',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        unit: '元',
        width: 11,
        widthPx: 100
    },
    kRate: {
        label: '職級係數',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        width: 10,
        widthPx: 80
    },
    fyc: {
        label: '實發服務津貼',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        unit: '元',
        width: 12,
        widthPx: 110
    },
    remark: {
        label: '備註',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'left',
        width: 10,
        widthPx: 85
    }
}

const insuranceYearEndBonusColumns = {
    bonusName: {
        label: '津貼類別',
        sortEnabled: false,
        isFilterable: false,
        type: 'date',
        position: 'left',
        rowNumber: 1,
        width: 12,
        widthPx: 147
    },
    period: {
        label: '工作月',
        sortEnabled: false,
        isFilterable: false,
        type: 'date',
        position: 'left',
        rowNumber: 2
    },
    handler: {
        label: '招攬人',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 1,
        width: 10,
        widthPx: 100
    },
    takeName: {
        label: '領佣人',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 2
    },
    productCode: {
        label: '商品代號',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 80
    },
    paymentPeriod: {
        label: '年期',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 1,
        width: 10,
        widthPx: 55
    },
    payType: {
        label: '繳別',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 2
    },
    paymentYear: {
        label: '年度',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 1,
        width: 10,
        widthPx: 55
    },
    paymentCount: {
        label: '繳次',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 2
    },
    fyp: {
        label: '保費',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        unit: '元',
        rowNumber: 1,
        width: 12,
        widthPx: 110
    },
    fyb: {
        label: '代理費',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        unit: '元',
        rowNumber: 2
    },
    fya: {
        label: '年終獎金',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        unit: '元',
        width: 11,
        widthPx: 100
    },
    kRate: {
        label: '職級係數',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        rowNumber: 1,
        width: 10,
        widthPx: 80
    },
    releaseRate: {
        label: '獎金率',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        rowNumber: 2
    },
    fyc: {
        label: '實發年終獎金',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        unit: '元',
        width: 12,
        widthPx: 110
    },
    remark: {
        label: '備註',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'left',
        width: 10,
        widthPx: 85
    }
}

const insuranceContinuedBonusColumns = {
    bonusName: {
        label: '津貼類別',
        sortEnabled: false,
        isFilterable: false,
        type: 'date',
        position: 'left',
        rowNumber: 1,
        width: 12,
        widthPx: 147
    },
    period: {
        label: '工作月',
        sortEnabled: false,
        isFilterable: false,
        type: 'date',
        position: 'left',
        rowNumber: 2
    },
    handler: {
        label: '招攬人',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 1,
        width: 10,
        widthPx: 100
    },
    takeName: {
        label: '領佣人',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 2
    },
    productCode: {
        label: '商品代號',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 80
    },
    paymentPeriod: {
        label: '年期',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 1,
        width: 10,
        widthPx: 55
    },
    payType: {
        label: '繳別',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 2
    },
    paymentYear: {
        label: '年度',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 1,
        width: 10,
        widthPx: 55
    },
    paymentCount: {
        label: '繳次',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        rowNumber: 2
    },
    fyp: {
        label: '保費',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        unit: '元',
        rowNumber: 1,
        width: 12,
        widthPx: 110
    },
    fyb: {
        label: '代理費',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        unit: '元',
        rowNumber: 2
    },
    fya: {
        label: '業績',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        unit: '元',
        width: 11,
        widthPx: 100
    },
    kRate: {
        label: '職級係數',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        rowNumber: 1,
        width: 10,
        widthPx: 80
    },
    releaseRate: {
        label: '門檻比例',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        rowNumber: 2
    },
    fyc: {
        label: '佣金',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'right',
        unit: '元',
        width: 12,
        widthPx: 110
    },
    remark: {
        label: '備註',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'left',
        width: 10,
        widthPx: 85
    }
}

const insuranceImageColumns = {
    date: {
        label: '通知日期',
        sortEnabled: false,
        isFilterable: false,
        type: 'date',
        position: 'left',
        width: 15,
        widthPx: 120
    },
    name: {
        label: '照會因素-照會內容 (備註)',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'left',
        width: 80,
        widthPx: 75
    },
    link: {
        label: '附件',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        width: 5,
        widthPx: 50
    }
}

const insuranceEventColumns = {
    complete: {
        label: '',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'left',
        width: 5,
        widthPx: 30
    },
    status: {
        label: '',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'left',
        width: 12,
        widthPx: 72
    },
    notifyDate: {
        label: '通知日期',
        sortEnabled: false,
        isFilterable: false,
        type: 'date',
        position: 'left',
        width: 20,
        widthPx: 110
    },
    title: {
        label: '照會因素-照會內容 (備註)',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'left',
        width: 45,
        widthPx: 125
    },
    attached: {
        label: '附件',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        headerPosition: 'center',
        position: 'left',
        width: 25,
        widthPx: 80
    }
}
const customerFilterTitles = {
    titleType: '制度',
    manCodes: '帳號',
    birthdayType: '生日/增歲',
    dateStart: '日期(起)',
    dateEnd: '日期(迄)',
    _seq: ['manCodes', 'birthdayType', 'dateStart', 'dateEnd']
}

const customerColumns = {
    flag: {
        label: '保險年齡狀態標誌',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 0,
        widthPx: 0,
        alias: {
            flagTrans: {
                label: '保險年齡狀態',
                position: 'center'
            }
        },
        isUnmovable: true,
        hasTips: false
    },
    insLink: {
        label: '保單',
        sortEnabled: false,
        isFilterable: false,
        isUnmovable: true,
        isUnhide: true,
        type: 'string',
        position: 'center',
        isExcelHide: true,
        width: 8,
        widthPx: 60,
        hasTips: false
    },
    //二月中先只上保單查詢的對接資料功能
    // supplierInfo: {
    //     label: '最新資訊',
    //     sortEnabled: false,
    //     isFilterable: false,
    //     type: 'string',
    //     position: 'center',
    //     width: 8,
    //     widthPx: 90
    // },
    remark: {
        label: '備註',
        sortEnabled: false,
        isFilterable: false,
        isUnmovable: true,
        isUnhide: true,
        type: 'string',
        position: 'center',
        isExcelHide: true,
        width: 8,
        widthPx: 60
    },
    name: {
        label: '姓名',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 12,
        widthPx: 90,
        isUnmovable: true,
        isUnhide: true,
        hasTips: false
    },
    id: {
        label: '身分證號碼',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 20,
        widthPx: 110,
        hasTips: false
    },
    birthday: {
        label: '生日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 17,
        widthPx: 100,
        hasTips: false
    },
    age: {
        label: '年齡',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        width: 12,
        widthPx: 90,
        unit: '歲',
        hasTips: false
    },
    insBirthday: {
        label: '保險年齡增加日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 17,
        widthPx: 100,
        hasTips: false
    },
    insAge: {
        label: '保險年齡',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        width: 12,
        widthPx: 90,
        unit: '歲',
        hasTips: false
    },
    insType: {
        label: '保單類型',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 120,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '該客戶購買過的保單類型',
                mobile: '「保單類型」是指該客戶購買過的保單類型'
            }
        }
    },
    mobile: {
        label: '手機/電話',
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 18,
        widthPx: 120,
        hasTips: false
    },
    address: {
        label: '地址',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'left',
        width: 40,
        widthPx: 250,
        hasTips: false
    }
}

const customerDescriptions = [
    `客戶備註相關資訊於帳號關閉後將不再開放查詢索取。`
]

const notificationFilterTitls = {
    manCode: '帳號',
    _seq: ['manCode']
}

const notificationSelfColumns = {
    complete: {
        label: '勾選完成通知',
        sortEnabled: false,
        isFilterable: false,
        isUnmovable: true,
        isUnhide: true,
        type: 'string',
        position: 'left',
        width: 5,
        widthPx: 50
    },
    //二月中先只上保單查詢的對接資料功能
    // supplierInfo: {
    //     label: '最新資訊',
    //     sortEnabled: false,
    //     isFilterable: false,
    //     type: 'string',
    //     position: 'center',
    //     width: 8,
    //     widthPx: 90
    // },
    notifyState: {
        label: '通知狀態',
        sortEnabled: true,
        isFilterable: true,
        isUnmovable: true,
        type: 'string',
        position: 'center',
        width: 15,
        widthPx: 110
    },
    documentLinks: {
        label: '附件',
        sortEnabled: true,
        isFilterable: false,
        isUnmovable: true,
        type: 'string',
        position: 'center',
        width: 5,
        widthPx: 70
    },
    notice: {
        label: '保單通知',
        sortEnabled: false,
        isFilterable: false,
        type: 'string',
        position: 'center',
        isExcelHide: true,
        width: 8,
        widthPx: 90,
        isUnmovable: true
    },
    title: {
        label: '照會內容',
        sortEnabled: true,
        isFilterable: false,
        type: 'string',
        position: 'center',
        width: 19,
        widthPx: 150
    },
    insStatus: {
        label: '保單狀態',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 80
    },
    notifyDate: {
        label: '通知日期',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 15,
        widthPx: 105
    },
    checkedAt: {
        label: '完成日期',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 15,
        widthPx: 105
    },
    notifyGroupTypeText: {
        label: '通知類型',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 19,
        widthPx: 150
    },
    supplier: {
        label: '保險公司',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 15,
        widthPx: 110
    },
    insNo: {
        label: '保單號碼',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 19,
        widthPx: 150
    },
    payer: {
        label: '要保人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 9,
        widthPx: 100
    },
    insured: {
        label: '被保人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 9,
        widthPx: 100
    },
    jobTypeText: {
        label: '險種',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 11,
        widthPx: 60
    },
    solicitorOri: {
        label: '招攬人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 90
    }
}

const notificationOrgColumns = {
    insStatus: {
        label: '保單狀態',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 80
    },
    notifyDate: {
        label: '通知日期',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 15,
        widthPx: 105
    },
    notifyGroupTypeText: {
        label: '通知類型',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 13,
        widthPx: 110
    },
    supplier: {
        label: '保險公司',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 15,
        widthPx: 110
    },
    salesName: {
        label: '業務員姓名',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 12,
        widthPx: 100
    },
    //2023/03/15 前後端會議決議移除組織通知列表的被保人、要保人欄位
    // payer: {
    //     label: '要保人',
    //     sortEnabled: true,
    //     isFilterable: true,
    //     type: 'string',
    //     position: 'center',
    //     width: 10,
    //     widthPx: 100
    // },
    // insured: {
    //     label: '被保人',
    //     sortEnabled: true,
    //     isFilterable: true,
    //     type: 'string',
    //     position: 'center',
    //     width: 10,
    //     widthPx: 100
    // },
    jobTypeText: {
        label: '險種',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 90
    },
    solicitorOri: {
        label: '招攬人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 90
    }
}

export {
    insuranceColumns,
    insuranceFilterTitles,
    insuranceContentColumns,
    insuranceProductColumns,
    insuranceSalesColumns,
    insuranceCommissionBonusColumns,
    insuranceServiceAllowanceColumns,
    insuranceYearEndBonusColumns,
    insuranceContinuedBonusColumns,
    insuranceImageColumns,
    insuranceEventColumns,
    customerColumns,
    notificationFilterTitls,
    notificationSelfColumns,
    notificationOrgColumns,
    customerFilterTitles,
    customerDescriptions
}
